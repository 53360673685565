import { graphql } from "gatsby"
import React from "react"
import CareersPage from '../components/pages/Careers'

const lang = 'en'

const Careers = ({ data, pageContext }) => {
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_en,
    titleSeo: seoDataJson.title_en,
    description: seoDataJson.description_en,
    alternateLanguage: seoDataJson.alternateLanguage_en,
    alternateUrl: seoDataJson.alternateUrl_en,
  }

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "CAREERS"
  })

  return (
    <CareersPage
      lang={lang}
      data={data}
      seoData={seoData}
      urls={resultUrls}
    />
  )
}

export const pageQuery = graphql`
  query careersEnQuery {
    careersImagesDesktop: allFile(
      filter: {
        relativePath: { glob: "group/careers/desktop/*" }
        extension: { eq: "jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    careersImagesMobile: allFile(
      filter: {
        relativePath: { glob: "group/careers/mobile/*" }
        extension: { eq: "jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    seoDataJson(name: { eq: "careers" }) {
      url_en
      title_en
      description_en
      alternateLanguage_en
      alternateUrl_en
    }
    allCareersJson(filter: { lang: { eq: "en" } }) {
      edges {
        node {
          name
          title
          sendTo
          lang
        }
      }
    }
  }
`

export default Careers
